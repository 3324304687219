<template >
  <div class="w-full flex justify-center z-40" >
    <transition name="fade">
      <contact-modal v-show="contactModal" @closeModal="contactModal = false"/>
    </transition>
    <transition name="fade">
      <faq-modal v-show="faqModal" @closeModal="faqModal = false"/>
    </transition>
    <modal-nav  v-show="modalnav" @closeModal="modalnav = false">
      <div slot="body" class="flex flex-col items-center">
        <router-link
          to="/emprestimo"
          v-if="showSimulation"
          class="link" >Simule
        </router-link>
        <a
          href="https://blog.tutudigital.com.br/"
          target="_blank"
          class="link"
          rel="noreferrer">Blog
        </a>
        <p
          @click="modalnav = false; contactModal = true"
          class="link">Contato
        </p>
        <p
          @click="modalnav = false; faqModal = true"
          class="link">FAQ
        </p>

         <router-link
          to="/parceiros"
          class="link">Parceiros
        </router-link>

      <router-link
          id="loginbusiness"
          to="/tomador/login"
          class="link">Entrar como empresa
        </router-link>
        <router-link
          to="/investidor/login"
          class="link"> Entrar como investidor
        </router-link>
        <router-link
          to="/parceiro/login"
          class="link"> Entrar como parceiro
        </router-link>
      </div>
      <div slot="footer">
        <p class="text-center text-xss text-gray-500">Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.</p>
      </div>
    </modal-nav>
    <header class="mt-4">
      <router-link :class="logo" to="/"></router-link>
      <img @click="modalnav = true" class="bars" src="../../assets/images/menu.svg" alt="">
      <nav>
        <router-link
          to="/emprestimo"
          v-if="showSimulation"
          class="link" :class="isWhiteText">Simule
        </router-link>
        <a
          href="https://blog.tutudigital.com.br/"
          target="_blank"
          class="link" :class="isWhiteText"
          rel="noreferrer">Blog
        </a>
        <p
          @click="modalnav = false; contactModal = true"
          class="link" :class="isWhiteText">Contato
        </p>
        <p
          @click="modalnav = false; faqModal = true"
          class="link" :class="isWhiteText">FAQ
        </p>
         <router-link
          to="/parceiros"
          class="link" :class="isWhiteText">Parceiros
        </router-link>
        <div v-if="$store.state.isLoggedIn" class="dropdown">
          <img class="avatar" :src="$store.state.investor.avatar"/>
          <div class="dropdown_content">
            <p class="dropdown_button" @click="verifyRoles">Painel</p>
            <p class="dropdown_button" @click="$store.dispatch('setLogout'); $emit('closeModal')">Sair</p>
          </div>
        </div>
        <a
          v-else
          href="#"
          @click="$emit('login')"
          class="link_sign_in"
        >
          Entrar
        </a>
      </nav>
    </header>
  </div>
</template>

<script>
import ModalNav from '@/components/Modal/ModalNav'
import ContactModal from '@/components/Modal/ContactModal'
import FaqModal from '@/components/Modal/FaqModal'

export default {
  props: ['white'],
  name: 'navbar',
  components: { ModalNav, ContactModal, FaqModal },
    watch: {
    contactModal(){
      if(this.contactModal){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    }
  },
  data () {
    return {
      modalnav: false,
      contactModal: false,
      faqModal: false
    }
  },
  computed: {
    showSimulation () {
      const path = this.$route.path.split('/')
      return path[1] !== 'emprestimo' && path[1] !== 'microcredito'
    },
    isWhiteText(){
      if(this.white) return 'text-white'
    },
    logo(){
      if(this.white) return 'logo-branco'

      return 'logo'
    },
  },
  mounted: function(){
  },
  methods: {
    verifyRoles () {
      if (this.$store.state.roles.split(',').length === 1 && this.$store.state.roles.toLowerCase().includes('investidor')) {
        this.$router.push('/investidor/painel')
      } else if (this.$store.state.roles.split(',').length === 1 && this.$store.state.roles.toLowerCase().includes('parceiro_tomador')) {
        this.$router.push('/parceiro/painel')
      } else if (this.$store.state.roles.split(',').length === 1 && this.$store.state.roles.toLowerCase().includes('tomador')) {
        this.$router.push('/tomador/painel')
      }
      this.$emit('login')
    }
  }
}
</script>

<style lang="sass" scoped>
.avatar
  @apply bg-no-repeat bg-center h-14 w-14 shadow rounded-full object-cover
  @screen lg
    @apply cursor-pointer

.badge
  @apply flex items-center justify-center bg-red-600 rounded-full text-xs text-white h-4 w-4 absolute bottom-0 right-0 shadow

header
  @apply h-16 w-full flex flex-row items-center justify-between
  @screen md
    @apply h-16 w-full flex flex-row items-center justify-between
  @screen lg
    @apply h-16 w-full flex flex-row items-center justify-between

.logo
  @apply bg-no-repeat bg-center h-10 w-32 cursor-pointer bg-contain
  background-image: url("../../assets/images/logo.svg")
  @screen md
    @apply h-14 w-40

.logo-branco
  @apply bg-no-repeat bg-center h-10 w-32 cursor-pointer bg-contain
  background-image: url("../../assets/images/logo-branco-tutu-.png")
  @screen md
    @apply h-14 w-40


nav
  @apply hidden
  @screen md
    @apply block flex items-center
  @screen lg
    @apply block flex items-center

.link
  @apply cursor-pointer
  @screen md
    @apply text-base font-medium text-secondary mr-10
  @screen lg
    @apply text-base font-medium text-secondary mr-10

.text-white
  color: #cbdbf5

.link_sign_in
  @screen md
    @apply text-base font-medium text-primary border border-primary rounded-full py-2 px-8
    &:focus, &:hover
      @apply text-white bg-primary
  @screen lg
    @apply text-base font-medium text-primary border border-primary rounded-full py-2 px-8
    &:focus, &:hover
      @apply text-white bg-primary

.bars
  @apply block w-6 h-6
  @screen md
    @apply hidden
  @screen lg
    @apply hidden

.dropdown
  @apply relative inline-block
  &:hover .dropdown_content,
  &:focus .dropdown_content
      @apply block
  &_content
    @apply hidden absolute shadow-md z-40 bg-gray-100 text-xs right-0 rounded-sm
    min-width: 9rem
  &_button
    @apply p-3 no-underline block cursor-pointer
    &:hover
      @apply bg-gray-400
    &:first-child
      @apply rounded-t-sm
    &:last-child
      @apply rounded-b-sm
</style>
