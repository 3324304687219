export default [
  {
    title: `Existe alguma cobrançade taxas de transferências, como TED ou DOC?`,
    text: `Em sua carteira digital –wallet, disponibilizamos duas transferências gratuitas ao mês para o banco cadastrado, passando disso é cobrado o valor de R$ 4,00 por transferência.`,
    show: false
  },
  {
    title: `Como acompanho os meus investimentos?`,
    text: `Em nossa plataforma existe um espaço disponível pra você investidor, um painel bem dinâmico que mostra todas as operações disponíveis para investimentos, seus valores investidos e recebidos, sua carteira digital - wallet, extratos, mensagens da TuTu, repagamentos e outras informações importantes.`,
    show: false
  },
  {
    title: `Como alterar minha conta bancária?`,
    text: `No Painel do Investidor é possível alterar suas informações a qualquer momento na opção <b>Meu Cadastro</b>. É importante lembrar que a conta deverá possuir mesma titularidade.`,
    show: false
  },
  {
    title: `Como construo minha carteira de investimentos na TuTu?`,
    text: `Visto que são investimentos de alto risco,o segredo para que sua carteira seja mais rentável e segura é a diversificação das empresas e dos valores de empréstimos escolhidos.`,
    show: false
  },
  {
    title: `Como é feito o retorno do valor investido?`,
    text: `No momento em que você e outros investidores conseguem participar da rodada, uma formalização é feita através de uma instituição financeira parceira. O montante levantado para o financiamento das empresas solicitante é repassado para o tomador de empréstimo, e após esse processo, a cada 30 dias, quando pago no vencimento, o valor é passado para você no próximo dia útil, o chamado repagamento. Lembrando que, a questão de 30 dias pode ser diferente, caso haja carência e outros fatores.
    Esse repagamento mensal é composto por amortização + juros. Seguindo o modelo da Tabela Price, onde a amortização começa com valor menor e vai aumentando, quanto aos juros, começa com o valor maior e vai diminuindo com o tempo. Dessa forma a dívida do tomador vai se tornando menor.
    Para facilitar, temos disponível no Painel, o fluxo de repagamentos.
    Você encontra esse simulador em nosso painel em:Escolha uma das oportunidades → Registrar interesse → Entenda o Fluxo de Repagamento Esperado →Fluxo de Repagamento.`,
    show: false
  },
  {
    title: `Como faço a declaração do imposto de renda dos investimentos?`,
    text: ``,
    show: false
  },
  {
    title: `Como funciona a análise de crédito da Tutu?`,
    text: `Nosso modelo proprietário de crédito conta com tecnologia de acesso a big-data, inteligência artificial, machine-learning, acesso a mais de 600 bases alternativas além dos birôs tradicionais do mercado, como Boa Vista e Serasa. Essas informações compõem um algoritmo que nos auxilia com as decisões e classificações de risco Somado a isso contamos com um time de analistas que avaliam a operação como um todo: a saúde financeira, veracidade das informações e a viabilidade do negócio.`,
    show: false
  },
  {
    title: `Como funciona a tributação de IR?`,
    text: ``,
    show: false
  },
  {
    title: `Como funciona o cálculo da TIR (Taxa Interna de Retorno)?`,
    text: `A TIR (Taxa Interna de Retorno), trata-se basicamente da taxa de desconto aplicada ao fluxo de caixa das parcelas de empréstimo, que resulta no juros de rentabilidade do investimento. Refere-se a taxa base de cálculo para a Tabela Price.`,
    show: false
  },
  {
    title: `Como funciona o investimento da TuTu?`,
    text: `O investimento acontece de forma bem simples. Primeiro precisamos que você se cadastre em nosso site e aguarde a aprovação. Caso os dados todos os dados estejam corretos, você já esta apto a começar a investir.
    Enquanto isso, as empresas que estão em busca de empréstimo, passam pela nossa análise de crédito que tem como objetivo selecionar tomadores que tenham um bom histórico financeiro, sejam empreendedores, capazes de fomentar a economia de sua região e que precisem de alavancagem para investir em seu crescimento.
    Após essa análise as empresas escolhidas entram para um processo que chamamos de “rodadas”, que geralmente acontecem toda segunda, quarta e sexta feira. Para ter acesso às informações das empresas, quais os valores solicitados de cada rodada, é preciso estar atento ao seu <b>Painel do Investidor</b> na aba <b>Investir</b>. Lá você consegue analisar o perfil da empresa tomadora, simular os seus retornos e decidir qual o valor de investimento é de seu interesse. Após decidido um boleto é gerado no valor definido, ou caso você tenha algum valor em sua carteira digital -wallet(link), o valor é transferido. Pronto! Agora você já é investidor aqui na TuTu.`,
    show: false
  },
  {
    title: `Como realizo reinvestimentos usando meu saldo da Wallet?`,
    text: `Os repagamentos são transferidos mensalmente para wallet, com isso, ou você transfere o valor para sua conta bancária ou você reinveste em novas empresas que estão disponíveis.`,
    show: false
  },
  {
    title: `Como recebo meus rendimentos?`,
    text: `O fluxo de repagamento fica disponível em nosso painel. Os repagamentos são mensais, sem custo para o investidor. Você recebe os valores em sua conta da carteira digital – wallet, onde o acesso está disponível em seu Painel do Investidor.`,
    show: false
  },
  {
    title: `Como transfiro os recursos?`,
    text: `Dentro do Painel do Investidor, seus valores de repagamento ficam em sua carteira digital - wallet,você possui duas transferências gratuitas ao mês para o banco cadastrado, passando disso é cobrado o valor de R$ 4,00 por transferência.`,
    show: false
  },
  {
    title: `De onde vem a comissão da TuTu?`,
    text: `A TuTu é remunerada através de uma comissão da prestação de serviço às empresas.
    Essa taxa é descontada no ato do desembolso do valor e o montante, varia de acordo com cada solicitação.`,
    show: false
  },
  {
    title: `Existe algum custo para saque da Carteira Virtual?`,
    text: `Em nossa wallet disponibilizamos duas transferências gratuitas ao mês para o banco cadastrado, passando disso é cobrado o valor de R$ 4,00 por transferência.`,
    show: false
  },
  {
    title: `Existe garantia do FGC?`,
    text: `Os investimentos de peer-to-peerlending não são assegurados pelo Fundo Garantidor de Crédito (FGC) ou qualquer outra instituição, uma vez que os aportes realizados são considerados depósitos vinculados a operações ativas (empréstimos) da instituição financeira que concede o crédito.`,
    show: false
  },
  {
    title: `Minha conta é conjunta, posso utilizá-la?`,
    text: `Sim, você pode utilizar qualquer conta que seja titular.`,
    show: false
  },
  {
    title: `Moro no exterior, posso investir? (brasileiros e estrangeiros)`,
    text: `Não, Você pode investir na Tutu desde que seja Pessoa Física, devidamente inscritas no cadastro de Pessoas Físicas do Ministério da Fazenda (CPF/MF), possua comprovante de residência no Brasil, ter no mínimo 18 (dezoito) anos e possuir conta corrente em Instituição Financeira. `,
    show: false
  },
  {
    title: `O Investimento na Tutu possui garantias?`,
    text: `A garantia das operações na TuTu podem variar de aval, recebíveis de cartões, imóveis e automóveis. As informações sobre cada operação está disponível em nosso painel.`,
    show: false
  },
  {
    title: `O que é "valor em trânsito"?`,
    text: `O processo de formalização da operação dependerá do aporte de todos os investidores. Os valores ficam em trânsito até que a operação seja concluída e devidamente formalizada na instituição financeira parceira.`,
    show: false
  },
  {
    title: `O que é a classificação do empréstimo (ou score) da operação?`,
    text: ``,
    show: false
  },
  {
    title: `O que é o teste de Perfil de risco do investidor (Suitability)?`,
    text: `O suitability, que em português significa verificação a adequação do produto ao cliente, consiste na aplicação de um formulário utilizado pela TuTu para definir o perfil de investidor de cada pessoa. Este formulário dirá, através de diversas perguntas, se o investidor está alinhado ao risco envolvido no investimento de peer-to-peer. O risco de investimento é alto, podendo até haver perda do valor investido caso a empresa não pague. Felizmente, tal perda nunca ocorreu na TuTu, mas queremos lembrar que o risco de crédito é do investidor.
    Já esclarecemos que a empresa em atraso efetua o pagamento com juros e multa, e estes são repassados ao investidor.`,
    show: false
  },
  {
    title: `O que preciso para me cadastrar como investidor?`,
    text: `Para investir na TuTu é simples, basta você fazer o seu cadastro no painel de investidores, anexar seus documentos (RG ou CNH e comprovante de residência) e preencher o Suitability. `,
    show: false
  },
  {
    title: `Onde fica o dinheiro da Carteira Virtual (wallet) da TuTu?`,
    text: `Todos os valores depositados na carteira virtual ficam com a nossa parceira IUGU, um serviço de pagamento que administra os recursos da carteira virtual da TuTu através de um arranjo de pagamento.
    O arranjo de pagamento é o conjunto de regras e procedimentos que disciplina a prestação de determinado serviço de pagamento ao público.
    Os arranjos integrantes do SPB (Sistema de Pagamentos Brasileiro) foram regulamentados pelo Bacen em 2013, com a publicação da circular 3.682.
    Dois anos depois, outra circular (3.765) estipulou que os participantes desses arranjos estivessem dentro da Grade Única de Liquidação, criada pela CIP (Câmara Interbancária de Pagamentos) para, como diz o nome, centralizar todas as liquidações de pagamentos no país. Em 2016, por meio da circular 3.815, o Bacen então definiu o prazo de 28 de setembro de 2017 para essa adequação.
    Ou seja, com essas novas regras estipuladas, a TuTu realizou uma parceria com a IUGU para se adequar às regras de arranjos do Bacen e, assim, se credenciou à CIP antes da data estipulada.`,
    show: false
  },
  {
    title: `Por que cada captação oferece uma rentabilidade especifica?`,
    text: `A rentabilidade é calculada com base na composição do resultado de uma análise de crédito da empresa, que avalia o risco de inadimplência das empresas, o total de empréstimo solicitado e o número de parcelas.`,
    show: false
  },
  {
    title: `Posso resgatar o valor total do investimento antes do término?`,
    text: `Antes de realizar o investimento você visualizará todo o fluxo de repagamento que fora acordado com o tomador do crédito. Sendo assim não é possível resgatar o valor antes do programado. Os repagamentos na TuTu são feitos mensalmente em sua conta cadastrada sem custo algum. `,
    show: false
  },
  {
    title: `Quais as taxas cobradas pela Tutu?`,
    text: `As taxas da Tutu estão atreladas ao serviço prestado. Sendo assim, ao Registrar Interesse em uma empresa Tomadora você visualizará o Retorno Líquido do Investimento, portando as taxas cobradas não afetarão a sua rentabilidade.`,
    show: false
  },
  {
    title: `Quais as vantagens dos investimentos da TuTu?`,
    text: `As vantagens para o investidor são muitas, começando pelo alto retorno. O grande diferencial do investimento na TuTu é o impacto social que o investimento gera. Quando você investe em uma pequena empresa você fomenta o mercado, financiando uma cadeia produtiva que acontece por trás do negócio em si. (falar mais do impacto)`,
    show: false
  },
  {
    title: `Qual a duração dos investimentos?`,
    text: `O prazo de cada investimento será de acordo com a empresa que o investidor escolher para investir. `,
    show: false
  },
  {
    title: `Qual a liquidez dos investimentos?`,
    text: `Os repagamento são mensais. Conforme o fluxo de repagamento apresentado. Como todo o dinheiro investido numa empresa é diretamente repassado para o tomador do empréstimo, não é possível resgatar o investimento antes do prazo sem que seja uma solicitação do tomador. `,
    show: false
  },
  {
    title: `Qual a rentabilidade dos investimentos?`,
    text: `A taxa interna de retorno do investimento na Tutu é de 2,52% ao mês. Taxa bem superior aos títulos de renda fixa praticados pelo mercado.`,
    show: false
  },
  {
    title: `Qual o procedimento da TuTu em caso de atraso no pagamento da parcela de empréstimo?`,
    text: `Temos uma esteira de cobrança que funciona da seguinte maneira: com 10, 7, 2 dias antes e no vencimento enviamos um e-mail de aviso; A partir do primeiro dia de atraso é iniciada a cobrança por telefone, que passa a ser diário; Com 10, 15 e 30 dias de atraso informamos que a negativação acontecerá a partir de 30 dias de atraso; De 31 a 59 dias de atraso informamos que daremos início ao processo judicial; Com 61 dias de atraso informamos ao tomador sobre a cobrança judicial; Atualização Periódica –As atualizações do status da cobrança serão enviadas através da Central de Mensagem toda sexta feira – caso não seja atualizado significa que o status continua o mesmo da semana anterior.`,
    show: false
  },
  {
    title: `Qual o risco de investir?`,
    text: `"Se a empresa atrasa, a TuTu faz todas as tratativas de cobrança possíveis dentro de 45 dias. Após esse período de inadimplência, a TuTu recorre a cobranças judiciais, com empresas especializadas.
    O risco de investimento é alto, podendo até haver perda do valor investido caso a empresa não pague. Felizmente, tal perda nunca ocorreu na TuTu, mas queremos lembrar que o risco de crédito é do investidor.
    Já esclarecemos que a empresa em atraso efetua o pagamento com juros e multa, e estes são repassados ao investidor."`,
    show: false
  },
  {
    title: `Qual o tipo de investimento da TuTu?`,
    text: `Renda Fixa. Renda fixa são todas as modalidades de investimento lastreados em títulos de dívida. `,
    show: false
  },
  {
    title: `Qual o valor máximo de investimento?`,
    text: `O valor máximo de investimento varia de operação para operação, tudo depende das condições de cada negócio. Essas informações estão sempre disponíveis na abertura de cada oferta.`,
    show: false
  },
  {
    title: `Qual o valor mínimo de investimento?`,
    text: `Existem dois valores mínimos para investimento, R$ 200,00 e R$ 500,00, esse valor vai depender do tipo da operação do tomador se é nano ou micro empresa. Essa informações são disponibilizadas na abertura de cada rodada.`,
    show: false
  },
  {
    title: `Quando o meu investimento começará a render?`,
    text: `37- Quando o meu investimento começará a render?
    Uma operação leva até 15 dias para ser formalizada. Após a formalização conta-se 30 dias para receber o primeiro repagamento. Em nossa plataforma você o status do seu investimento atualizado.`,
    show: false
  },
  {
    title: `Quem pode investir?`,
    text: `Pessoas físicas, devidamente inscritas no cadastro de Pessoas Físicas do Ministério da Fazenda (CPF/MF ), residente no Brasil, com no mínimo 18 (dezoito) anos e possuir conta corrente em Instituição Financeira. Todo investidor TuTu deve preencher o Suitability e possuir perfil de investidor adequado à modalidade peer-to-peerlending.`,
    show: false
  }
]
