<template>
  <div class="modal">
    <div class="backdrop" @click="close"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="close">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <div class="row">
            <button @click="showQuestionsBusiness = true;showQuestionsInvestor = false" :class="showQuestionsBusiness ? 'button_primary_active' : ''" class="button button_primary mr-2 md:mr-5">Tomador</button>
            <button @click="showQuestionsInvestor = true;showQuestionsBusiness = false" :class="showQuestionsInvestor ? 'button_secondary_active' : ''" class="button button_secondary ml-2 md:ml-5">Investidor</button>
          </div>
          <div class="p-4 md:mt-6">
            <table>
              <template v-if="showQuestionsBusiness">
                <tr v-for="(item,index) in questionsBusiness" :key="index">
                  <td @click="item.show = !item.show">
                    <span><i :class="`fas fa-${item.show ? 'minus' : 'plus'}`"></i></span>
                    {{`${index+1}. ${item.title}`}}
                  </td>
                  <transition name="slide">
                    <div v-show="item.show">
                      <div class="flex my-2 items-center justify-between">
                        <div v-html="item.text"></div>
                      </div>
                    </div>
                  </transition>
                </tr>
              </template>
              <template v-if="showQuestionsInvestor">
                <tr v-for="(item,index) in questionsInvestor" :key="index">
                  <td @click="item.show = !item.show">
                    <span><i :class="`fas fa-${item.show ? 'minus' : 'plus'}`"></i></span>
                    {{`${index+1}. ${item.title}`}}
                  </td>
                  <transition name="slide">
                    <div v-show="item.show">
                      <div class="flex my-2 items-center justify-between">
                        <div v-html="item.text"></div>
                      </div>
                    </div>
                  </transition>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsInvestor from '@/assets/js/questionsInvestor'
import QuestionsBusiness from '@/assets/js/questionsBusiness'
export default {
  name: 'faq',
  data () {
    return {
      showQuestionsBusiness: true,
      showQuestionsInvestor: false,
      questionsBusiness: QuestionsBusiness,
      questionsInvestor: QuestionsInvestor
    }
  },
  methods: {
    close () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative h-120 w-full bg-white z-50 flex flex-col pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-11/12 h-160

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2 text-lg

.content_modal
  @apply flex flex-col w-full px-4 overflow-auto
  @screen lg
    @apply px-8

.row
  @apply w-full flex justify-center
  @screen md
    @apply justify-center mt-6

.button
  @apply rounded-full bg-white text-sm shadow-md px-2 w-32 h-8
  @screen lg
    @apply w-40 h-10
  &.button_primary
    @apply border border-primary text-primary
    &_active
      @apply bg-primary text-white
  &.button_secondary
    @apply border border-secondary text-secondary
    &_active
      @apply bg-secondary text-white
  &:focus
    @apply outline-none

// Tabela
table
  @apply w-full border-none

table tr
  @apply py-1 w-full
  &:focus
    @apply outline-none

table td
  @apply border-b border-primary w-full flex items-center py-1 opacity-75 text-sm text-primary font-semibold cursor-pointer select-none
  @screen lg
    @apply text-base

table td span
  @apply text-sm py-1 mr-3
  @screen lg
    @apply mr-2
  &>i
    @apply text-xs

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>
