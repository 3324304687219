export default [{
        title: `Como funciona o pedido de empréstimo na Tutu Digital?`,
        text: `A Tutu Digital é uma plataforma digital que conecta pessoas jurídicas que precisam de empréstimo para o seu negócio com pessoas que querem investir/emprestar.`,
        show: false
    },
    {
        title: `Para quem a Tutu fornece empréstimo? `,
        text: `Para Microempreendedor, Micro e Pequenas Empresas`,
        show: false
    },
    {
        title: `Quais os requisitos para pedir empréstimo com a Tutu? `,
        text: `Ter negócio funcionando há mais de 15 meses e não ter protesto no nome da empresa ou dos sócios`,
        show: false
    },
    {
        title: `Estou negativado, consigo o empréstimo? `,
        text: `Não, mas assim que resolver essa situação poderá solicitar 60 dias após a primeira tentativa`,
        show: false
    },
    {
        title: `Estou começando um negócio, consigo empréstimo?`,
        text: `Não, só trabalhamos com negócios que funcionem há mais de 15 meses.`,
        show: false
    },
    {
        title: `Tenho um negócio, mas ainda não tenho CNPJ, posso conseguir o empréstimo?`,
        text: `Não, pois só conseguimos oferecer empréstimo para Pessoas Jurídicas. `,
        show: false
    },
    {
        title: `A Tutu cobra algum valor antecipado para a concessão do empréstimo?`,
        text: `Não exigimos nenhum depósito antecipado ou qualquer pagamento antecipado para a concessão de empréstimos.`,
        show: false
    },
    {
        title: `Qual a taxa de juros que a Tutu cobra?`,
        text: `A partir de 1,9% até 5,99%`,
        show: false
    },
    {
        title: `Preciso ter alguma garantia (carro ou casa) para solicitar o empréstimo?`,
        text: `Não trabalhamos com garantia de imóvel ou veículo, para a Tutu a coobrigação do(s) sócios da empresa que está solicitando o empréstimo é suficiente.`,
        show: false
    },
    {
        title: `Qual valor mínimo de empréstimo que posso pedir?`,
        text: `A partir de R$ 500,00. `,
        show: false
    },
    {
        title: `Quem são essas pessoas que estão me emprestando dinheiro?`,
        text: `São pessoas físicas ou jurídicas que acreditam no seu negócio e fazem esse empréstimo, o qual chamamos de investidor. `,
        show: false
    },
    {
        title: `Esses investidores terão algum poder sobre a minha empresa ou do lucro que a empresa me dá?`,
        text: `Não, eles apenas emprestam o dinheiro quando colocamos sua empresa em rodada em valores variados. `,
        show: false
    },
    {
        title: `Eu tenho contato com esses investidores?`,
        text: `Não, você não sabe quem está fazendo esse empréstimo, nossa plataforma recolhe o dinheiro quando alcançar o valor que você precisa e transfere para sua conta.`,
        show: false
    },
    {
        title: `Em quanto tempo recebo um retorno do pré cadastro?`,
        text: `Em até 3 minutos você recebe um retorno do pré cadastro`,
        show: false
    },
    {
        title: `Minha proposta foi pré aprovada, quais são os próximos passos?`,
        text: `Você entrará na plataforma com seu login, preencherá a ficha cadastral e anexará as documentações solicitadas.`,
        show: false
    },
    {
        title: `Se minha proposta for negada, o que eu faço?`,
        text: `Você receberá um email falando sobre a negativa e poderá solicitar novamente em 60 dias.`,
        show: false
    },
    {
        title: `Quais documentos eu preciso anexar se eu for pré aprovado? `,
        text: `<ul class='list-disc'>
            <p class='font-semibold'>Documentos do solicitante do Empréstimo (PESSOA FÍSICA):</p>
            <li>RG/CPF ou CNH</li>
            <li>Comprovante de Residência</li>
            <li>Certidão de Casamento ou Averbação</li>
            <li>Declaração de Imposto de Renda Pessoa Física</li>
            <br>
            <p class='font-semibold'>Documentos da Empresa solicitante do Empréstimo (PESSOA JURÍDICA):</p>
            <li>Contrato social consolidado e/ou última alteração contratual</li>
            <li>Comprovante de endereço da empresa dos últimos 90 dias</li>
            <li>Declaração de faturamento dos últimos 12 meses</li>
            <li>Extrato bancário dos últimos 4 meses</li>
            <li>Declaração de imposto de renda Pessoa Jurídica</li>
          </ul>`,
        show: false
    },
    {
        title: `Os documentos precisam ser digitalizados?`,
        text: `Sim, os documentos precisam estar coloridos, legíveis e dentro do prazo de validade. `,
        show: false
    },
    {
        title: `Sou MEI e não tenho contador, o que eu faço?`,
        text: `MEI não precisa de contador, você mesmo que preencherá a declaração de faturamento.`,
        show: false
    },
    {
        title: `Posso colocar o extrato que eu peguei em um cash? `,
        text: `Não, o extrato bancário tem que ser em OFX que você poderá tirar a partir da internet banking.`,
        show: false
    },
    {
        title: `O comprovante de residência não está no meu nome, o que fazer?`,
        text: `Se tiver no nome de algum parente de 1 grau não tem problema, caso não seja o caso terá que fazer uma declaração de residência com firma reconhecida em cartório.`,
        show: false
    },
    {
        title: `Sou isento da Declaração de Imposto de Renda pessoa física, o que fazer?`,
        text: `Você preencherá uma declaração de isenção. `,
        show: false
    },
    {
        title: `Depois do anexo de documentos tem mais alguma etapa?`,
        text: `Fazemos uma visita via vídeo. `,
        show: false
    },
    {
        title: `Como funciona essa visita via vídeo e para que serve?`,
        text: `Combinamos com você um horário para conversamos com você através de algum aplicativo de vídeo para conhecermos melhor o seu negócio.`,
        show: false
    },
    {
        title: `Depois de anexar os documentos e fazer a visita via vídeo, eu consegui o empréstimo?`,
        text: `Ainda não, nós te colocaremos em Rodada para que pessoas que acreditam no seu negócio possam investir, após conseguir todo o dinheiro do empréstimo, ai sim você conseguiu. `,
        show: false
    },
    {
        title: `Em quanto tempo o dinheiro é liberado na minha conta?`,
        text: `De 7 a 15 dias úteis após a aprovação do crédito. `,
        show: false
    },
    {
        title: `Não tenho conta no banco Pessoa Jurídica, posso receber na minha conta Pessoa Física?`,
        text: `Não, a Tutu só consegue fazer o depósito na conta de Pessoa Jurídica.`,
        show: false
    },
    {
        title: `A Tutu tem carência de quantos dias? `,
        text: `30 dias`,
        show: false
    },
    {
        title: `Por onde recebo os boletos para pagamento das parcelas?`,
        text: `Enviaremos o boleto via e-mail.`,
        show: false
    },
    {
        title: `Se eu atrasar as parcelas, o que acontece?`,
        text: `Você terá que pagar multa 2% e juros de 1%`,
        show: false
    },
    {
        title: `Se eu não conseguir pagar as parcelas, o que acontecerá comigo?`,
        text: `Após 15 dias do vencimento da parcela você será negativos e após 60 dias do vencimento da parcela iniciaremos um processo de execução`,
        show: false
    },
    {
        title: `Posso mudar o dia de pagamento das minhas parcelas?`,
        text: `Infelizmente, não.`,
        show: false
    },
    {
        title: `Se eu quiser quitar todo o meu empréstimo antes, tenho desconto?`,
        text: `Sim, o valor é atualizado para a data da quitação.`,
        show: false
    }
]